import React from "react";
import "./App.css";
import Bgvideo from "./components/Bgvideo/Bgvideo";
import Timer from "./containers/Timer/Timer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Bgvideo />
        <Timer />
      </header>
    </div>
  );
}

export default App;
