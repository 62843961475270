import React, { Component } from "react";
import classes from "./Timer.module.css";

class Timer extends Component {
  state = {
    day: null,
    hour: null,
    min: null,
    sec: null,
  };

  componentDidMount() {
    setInterval(() => {
      this.updateDate();
    }, 1000);
  }

  updateDate() {
    const target = new Date("2025/04/15 00:00:00");
    const now = new Date();

    const left_ts = target.getTime() - now.getTime();
    const day = parseInt(left_ts / (60 * 60 * 24 * 1000));
    const hour = parseInt((left_ts / (60 * 60 * 1000)) % 24);
    const min = parseInt((left_ts / (60 * 1000)) % 60);
    const sec = parseInt((left_ts / 1000) % 60);

    this.setState({
      day,
      hour,
      min,
      sec,
    });
  }

  render() {
    return (
      <div className={classes.Timer}>
        <p className={classes.text}>{this.state.day} days</p>
        <p className={classes.text}>{this.state.hour} hours</p>
        <p className={classes.text}>{this.state.min} minutes</p>
        <p className={classes.text}>{this.state.sec} seconds</p>
      </div>
    );
  }
}

export default Timer;
