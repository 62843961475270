import React from "react";
import video from "../../assets/bgvideo.mp4";
import pic from "../../assets/bgpic.jpg";
import classes from "./Bgvideo.module.css";

const bgvideo = () => {
  return (
    <div className={classes.Background}>
      <img src={pic} className={classes.video} />
    </div>
  );
};

export default bgvideo;
